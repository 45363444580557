import * as React from "react";
import styled from "styled-components";
import "../../styles/global.css";
import {theme} from '../../constants'

const InputField = (props: {
  label: string, value: string, type? : string, handleChange : (e: React.ChangeEvent<HTMLInputElement>) => void,
}) => {

    const {value,label, handleChange , type = 'text'} = props;

  return (
    <InputContainer>
      <LabelContainer>{label}</LabelContainer>
      <StyledInput type = {type} value = {value} onChange = {handleChange}/>
    </InputContainer>
  );
};

/*--------------Styling-----------------*/

const InputContainer = styled.div`
  max-width: 400px;
  width: 90%;
  font-weight: 600;
  margin:auto;
`;

const StyledInput = styled.input`
  width: 100%;
  margin-top:10px;
  margin-bottom:25px;
  height: 30px;
  position:relative;
  &:focus {
    outline-color:  ${theme.primaryColor};
  }
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  ::-webkit-calendar-picker-indicator {
    padding-left:   400px;
    position:absolute;
    background:red;
    opacity: 0;
    left: -25px;
  }
`

const LabelContainer = styled.div`
  text-align:left;
  font-size: 14px;
`;

export default InputField;
