import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import InputField from "../components/InputField";
import InputTextArea from "../components/InputTextArea";
import InfoField from "../components/InfoField";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import SEOHelmet from "../components/SEOHelmet";
import OverlayContent from "../components/Banner/OverlayContent";
import Grid from "@material-ui/core/Grid";
import { theme, contactBannerText, contactSubText } from "../constants";
import ContactBannerImg from "../images/contactbanner.jpeg";
import emailjs from "emailjs-com";
import Hidden from "@material-ui/core/Hidden";
import scrollTo from "gatsby-plugin-smoothscroll";

const ContactPage = ({ location }) => {
  useEffect(() => {
    if (location?.state?.service) {
      scrollTo("#form");
    } else if (location?.state?.scrollToTop) {
      scrollTo("#top");
    }
  }, []);

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [phone, setPhone] = useState("");

  const [email, setEmail] = useState("");

  const [referral, setReferral] = useState("");

  const [pickup, setPickup] = useState("");

  const [destination, setDestination] = useState("");

  const [groupSize, setGroupSize] = useState("");

  const [event, setEvent] = useState(location?.state?.service || null);

  const [dates, setDates] = useState("");

  const [time, setTime] = useState("");

  const [message, setMessage] = useState("");

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      (e.target.value.length === 3 || e.target.value.length === 7) &&
      e.target.value.length > phone.length
    ) {
      setPhone(e.target.value + "-");
    } else if (e.target.value.length > 12) {
      return;
    } else {
      setPhone(e.target.value);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleEventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEvent(e.target.value);
  };

  const handlePickupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPickup(e.target.value);
  };

  const handleDestinationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestination(e.target.value);
  };

  const handleReferralChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferral(e.target.value);
  };

  const handleGroupSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSize(e.target.value);
  };

  const handleDatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDates(e.target.value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const handleClearAll = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setDates("");
    setTime("");
    setReferral("");
    setPickup("");
    setDestination("");
    setGroupSize("");
    setEvent("");
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      alert("Please enter a valid Email Address");
    } else if (!isValidPhoneNumber(phone)) {
      alert("Please enter a valid Phone Number");
    } else if (!firstName || !lastName) {
      alert("Please enter your name");
    } else if (!message) {
      alert("Please enter a message");
    } else {
      const serviceId = "service_mdr3lwd";
      const templateId = "template_an8bani";
      const userId = "fzgnbv5K4tBCINaxq";

      const templateParams = {
        firstName,
        lastName,
        email,
        message,
        phone,
        referral: referral || "Not specified",
        time: time || "Not specified",
        dates: dates || "Not specified",
        pickup: pickup || "Not specified",
        destination: destination || "Not specified",
        event: event || "Not specified",
        groupSize: groupSize || "Not specified",
      };
      emailjs.send(serviceId, templateId, templateParams, userId).then(
        (response) => {
          console.log(response);
          if (response.status === 200) {
            alert(
              "Message sent successfully. We will be in contact with you shortly."
            );
          }
        },
        (error) => {
          if (error) {
            alert(
              "Message could not be set. Please try contacting us directly at memorieslimo@gmail.com or 905-391-9999"
            );
          }
        }
      );
    }
  };

  const isValidEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const isValidPhoneNumber = (number) => {
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
    return regex.test(String(number).toLowerCase());
  };

  const subTitle = () => <div>{contactBannerText}</div>;

  return (
    <ContactContainer id="top">
      <SEOHelmet
        title="Memories Limousine | Contact Us"
        description="Contact us for booking limousine service information"
      />
      <Header />
      <Banner showOverlay img={ContactBannerImg}>
        <OverlayContent title={"Contact Us"} subTitle={subTitle} />
      </Banner>
      <ContentContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ background: "" }}>
            <FormContainer>
              <FormTitle id="form">Get In Touch</FormTitle>
              <Divider />
              <FormSubTitle>{contactSubText}</FormSubTitle>
              <InputField
                value={firstName}
                label={"First Name*"}
                handleChange={handleFirstNameChange}
              />
              <InputField
                value={lastName}
                label={"Last Name*"}
                handleChange={handleLastNameChange}
              />
              <InputField
                value={phone}
                label={"Phone Number*"}
                handleChange={handlePhoneChange}
              />
              <InputField
                value={email}
                label={"Email*"}
                handleChange={handleEmailChange}
              />
              <InputField
                value={event}
                label={"Event Type"}
                handleChange={handleEventChange}
              />
              <InputField
                value={pickup}
                label={"Pick-Up Address"}
                handleChange={handlePickupChange}
              />
              <InputField
                value={destination}
                label={"Destination Address"}
                handleChange={handleDestinationChange}
              />
              <InputField
                value={groupSize}
                label={"Group Size"}
                handleChange={handleGroupSizeChange}
              />
              <InputField
                type="date"
                value={dates}
                label={"Date Needed"}
                handleChange={handleDatesChange}
              />
              <InputField
                type="time"
                value={time}
                label={"Time"}
                handleChange={handleTimeChange}
              />
              <InputField
                value={referral}
                label={"How did you hear about us?"}
                handleChange={handleReferralChange}
              />
              <InputTextArea
                value={message}
                label={"Message*"}
                handleChange={handleMessageChange}
              />
              <SubmitButton onClick={handleSubmit}> Submit </SubmitButton>
            </FormContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoContainer>
              <FormTitle style={{ marginBottom: 40 }}>
                Contact Information
              </FormTitle>
              <InfoField text={"Memorieslimo@gmail.com"} label={"Email:"} />
              <InfoField text={"905-391-9999"} label={"Phone Number:"} />
              <InfoField
                text={"40 Clune Pl, Whitby, ON L1R 2C5"}
                label={"Address:"}
              />
              <InfoField
                isLink
                url={"https://www.facebook.com/Memories-Limo-1027674930583752/"}
                text={"Facebook"}
                label={"Connect With Us:"}
              />
              <Hidden mdUp>
                <InfoField
                  isLink
                  hideLabel
                  url={"tel:+19053919999"}
                  text={"Call Now"}
                  label={"Call Now"}
                />
              </Hidden>
            </InfoContainer>
          </Grid>
        </Grid>
      </ContentContainer>
      <Footer />
    </ContactContainer>
  );
};

/*--------------Styling-----------------*/

const ContactContainer = styled.div`
  background-color: white;
  color: black;
`;

const ContentContainer = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 75px auto;
`;

const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 400;
  text-align: center;
`;

const Divider = styled.div`
  width: 120px;
  height: 2px;
  background: ${theme.primaryColor};
  margin: 10px auto;
`;

const FormSubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 350px;
  margin: auto;
  line-height: 24px;
  margin-bottom: 40px;
`;

const SubmitButton = styled.div`
  height: 40px;
  width: 150px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.primaryColor};
  font-weight: 400;
  margin: auto;
  cursor: pointer;
`;

const FormContainer = styled.div`
  @media (min-width: 960px) {
    border-right: 1px solid black;
  }
`;

const InfoContainer = styled.div`
  @media (max-width: 960px) {
    margin-top: 50px;
  }
`;

export default ContactPage;
