import * as React from "react";
import styled from "styled-components";
import "../../styles/global.css";



const InfoField = (props: {
  label: string, text: string, isLink?: boolean, url?: string, hideLabel?: boolean
}) => {

    const {text,label,isLink,url, hideLabel} = props;

  return (
    <InfoFieldContainer>
      {!hideLabel && <LabelContainer>{label}</LabelContainer>}
      {isLink ? <StyledLink href = {url}  target="_blank"> {text}</StyledLink>  :  <StyledText> {text} </StyledText>}
    </InfoFieldContainer>
  );
};

/*--------------Styling-----------------*/

const InfoFieldContainer = styled.div`
  width: 277px;
  margin:auto;
`;

const StyledText = styled.div`
  width: 100%;
  margin-top:5px;
  margin-bottom:20px;
  height: 30px;
  text-align:left;
`

const LabelContainer = styled.div`
  text-align:left;
  font-size: 16px;
  font-weight: 600;
`;

const StyledLink = styled.a`  
  display: block;
  text-align:left;
  width: 100px;
  cursor:pointer;
  margin-top:20px;
  margin-bottom:20px;
`

export default InfoField;
